import React from 'react';
import { MapData } from '@/pages/DeveloperConsolePage/Dashboard/MapManagePage/types';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import useURLGenerator from '@/hooks/use-url-generator';
import { MAP_DETAILED_PAGE_PATH, MAP_ID_KEY } from '@/routes/path';
import {
  Text,
  Stack,
  Box,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  Badge,
  Show,
} from '@chakra-ui/react';
import MapIcon from '../Icon/MapIcon';
import MoreIcon from '../Icon/MoreIcon';
import { Link as RouterLink } from 'react-router-dom';
import useMapOperations from '@/hooks/use-map-operations';
import useFetchUserRoles from '@/hooks/fetcher/use-fetch-user-roles';
import Link from '../Link';

enum MAP_STATUS {
  PROCESSING,
  FAST_MAP_READY,
  FINE_MAP_READY,
  READY,
  ERROR,
}

export interface MapListingItemProps {
  map: MapData;
  withCheckboxSelection?: boolean;
}

function decimalToBinary(decimal: number) {
  return (decimal >>> 0).toString(2).padStart(8, '0').split('').map(Number);
}

function binaryToStatus(binary: number[]) {
  const [optimize, findPlan, mapQuality, meshing, sfmCoarse, sfmFine, sfmAlign] = binary.reverse();
  return { optimize, findPlan, mapQuality, meshing, sfmCoarse, sfmFine, sfmAlign };
}

function getBadge({
  meshing,
  sfmCoarse,
  sfmFine,
  sfmAlign,
  optimize,
  findPlan,
  mapQuality,
}: ReturnType<typeof binaryToStatus>) {
  if (meshing && sfmFine && sfmAlign)
    return MAP_STATUS.READY; // all features are ready, SLAM, SfM, mesh
  else if (sfmFine && sfmAlign) return MAP_STATUS.FINE_MAP_READY; // SfM is ready
  else if (optimize && findPlan && mapQuality) return MAP_STATUS.FAST_MAP_READY; // SLAM is ready
  return MAP_STATUS.PROCESSING;
}

const MapListingItem: React.FC<MapListingItemProps> = ({ map, withCheckboxSelection }) => {
  const { data: roles } = useFetchUserRoles();
  const { generateURL } = useURLGenerator();
  const { t } = useTranslation();
  const { state, events } = useMapOperations();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      events.onSelectMap(map, true);
      return;
    }
    events.onUnselectMap(map, true);
  };
  const createdDate =
    map?.reg_dt_num && !isNaN(map?.reg_dt_num)
      ? format(new Date(map?.reg_dt_num * 1000), 'yyyy LL/dd kk:mm')
      : map?.reg_dt_short;
  const updatedDate =
    map?.update_dt_num && !isNaN(map?.update_dt_num)
      ? format(new Date(map?.update_dt_num * 1000), 'yyyy LL/dd kk:mm')
      : map?.update_dt_short;

  const handleCopyMapMenuClick = () => {
    events.onSelectMap(map);
    events.showCopyModal();
  };

  const handleDeleteMapMenuClick = () => {
    events.onSelectMap(map);
    events.showDeleteMapModal();
  };

  const handleShareMapClick = () => {
    events.onSelectMap(map);
    events.showExportMapModal();
  };

  const binary = decimalToBinary(map.process_status);
  const statuses = binaryToStatus(binary);

  const badge = map.map_proc_failed ? MAP_STATUS.ERROR : getBadge(statuses);

  return (
    <Stack
      direction="row"
      gap="4"
      alignItems="center"
      w="full"
      _hover={{ background: 'gray.50' }}
      pl="2">
      {withCheckboxSelection && (
        <Checkbox
          value={map.map_key}
          defaultChecked={!!state.selectedMaps.find((m) => m.map_key === map.map_key)}
          disabled={!map.map_availability}
          onChange={handleChange}
          name={map.map_key}
        />
      )}
      <Link to={generateURL(MAP_DETAILED_PAGE_PATH, { [MAP_ID_KEY]: map.map_key })}>
        <Text fontSize="lg">
          <MapIcon />
        </Text>
      </Link>

      <Stack w="300px" direction="row">
        <Text
          w="250px"
          fontSize="md"
          noOfLines={1}
          color={badge === MAP_STATUS.PROCESSING ? 'gray.500' : 'inherit'}>
          {map.map_name}
        </Text>
        <Box>
          {badge === MAP_STATUS.PROCESSING && (
            <Badge variant="solid" rounded="full" size="xs" colorScheme="gray">
              processing
            </Badge>
          )}
          {badge === MAP_STATUS.FINE_MAP_READY && (
            <Badge variant="solid" rounded="full" size="xs" colorScheme="green">
              Fine Map Ready
            </Badge>
          )}
          {badge === MAP_STATUS.FAST_MAP_READY && (
            <Badge variant="solid" rounded="full" size="xs" colorScheme="green">
              Fast Map Ready
            </Badge>
          )}
          {badge === MAP_STATUS.READY && (
            <Badge variant="solid" rounded="full" size="xs" colorScheme="green">
              Ready
            </Badge>
          )}
          {badge === MAP_STATUS.ERROR && (
            <Badge variant="solid" rounded="full" size="xs" colorScheme="red">
              Error
            </Badge>
          )}
        </Box>
      </Stack>
      <Show above="lg">
        <Box w="150px">
          <Text fontSize="2xs">{t('created_date')}</Text>
          <Text fontSize="sm">{createdDate}</Text>
        </Box>
      </Show>
      <Show above="lg">
        <Box w="150px">
          <Text fontSize="2xs">{t('last_update')}</Text>
          <Text fontSize="sm">{updatedDate}</Text>
        </Box>
      </Show>
      <Show above="lg">
        <Box w="80px">
          <Text fontSize="2xs">{t('app_usage')}</Text>
          <Text>{map.assigned_app_keys?.length || 0}</Text>
        </Box>
      </Show>

      {!withCheckboxSelection && (
        <Menu>
          <MenuButton as={IconButton} variant="ghost" icon={<MoreIcon />} size="sm" />
          <MenuList>
            <MenuItem
              as={RouterLink}
              to={generateURL(MAP_DETAILED_PAGE_PATH, { [MAP_ID_KEY]: map.map_key })}>
              {t('view')}
            </MenuItem>
            {roles?.admin && <MenuItem onClick={handleShareMapClick} isDisabled={!map.map_availability}>{t('share')}</MenuItem>}
            <MenuItem onClick={handleCopyMapMenuClick} isDisabled={!map.map_availability}>
              {t('copy')}
            </MenuItem>
            <MenuItem onClick={handleDeleteMapMenuClick}>
              {t('delete')}
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Stack>
  );
};

export default MapListingItem;
